import './about.scss';
import img from '../../assets/about/pexels-padrinan-3930091.jpg';
import { useInView } from 'react-intersection-observer';

export default function About() {
    const [refAbout, inView] = useInView({
        threshold: .3,
        triggerOnce: true
    });

    return (
        <>
            <div ref={refAbout} id="about" className={'container row justify-content-center' + (inView? ' about-fade-in': '')}>
                <section id="about-text" className="col-12 col-sm-11 col-md-10 col-lg-7 col-xl-5 fs-5 mb-3">
                    <h2>About me</h2>
                    <p>
                        Full-time hotel building maintenance worker looking to turn my hobby as
                        a web developer into my career. I got into web development through ideas
                        to improve the web, taking things apart to understand how
                        they worked, and wanting to build something that I was proud of.
                    </p>
                    <p>
                        The majority of my projects revolved around productivity and apps that
                        could save time. I started with small projects, such as jQuery plugins
                        on top of existing websites I used often, then moved to automation
                        with Puppeteer, and finally progressed to making my own full-stack apps.
                    </p>
                    <p>
                        Building maintenance is similar to programming because there is always
                        something new to learn: improvements on previous discoveries, and a large field
                        to explore. I&apos;m looking forward to taking up web development as a career.
                    </p>
                    
                </section>
                <div id="about-image-parent" className="col-12 col-sm-11 col-md-10 col-lg-5 col-xl-5 fs-5 mb-3">
                    <picture>
                        <img id="about-image" className="" src={img}></img>
                    </picture>
                </div>
            </div>
        </>
    );
}
