import ModalBase from "./ModalBase";

export default function ModalJWPaintAndRepair() {
  return (
    <ModalBase id="modal-jw-paint-and-repair" liveUrl="./projects/jw-paint-and-repair/index.html">
      <h1>JW Paint and Repair</h1>
      <p>
        Website to advertise a business for an independent contractor.  The client only had business cards 
        and an account with Nextdoor, but wanted something more professional.  My solution was to:
      </p>
      <ul>
        <li>Create a website</li>
        <li>Create a Google Business profile so he appears on Google Maps</li>
        <li>Google Voice for calls and spam prevention</li>
        <li>Formspree for forms + emails and spam prevention</li>
        <li>Hand off the domain account and show him how to connect, make changes, and how payments are handled</li>
      </ul>
      <h3>First impressions</h3>
      <p>
        The vision for the website was minimalistic, a friendly next door handyman, a gallery, a contact page, and easy to make changes.
        Initially I tried WYSIWYG services such as Squarespace, GoDaddy, and Google Pages, so the client could make
        small changes on their own without needing a programmer.  For each of these services, I struggled with either the learning
        curve or I found it wasn&apos;t powerful enough.  Notably I could not get the mobile view the way I wanted, and the
        mobile market share accounts for more than 50% of traffic these days.  It was important to have a website that looks great
        in both.
      </p>

      <h3>Actual implementation</h3>
      <p>
        I settled by writing the website from scratch and used a combination of Bootstrap, HTML, and CSS.  I wrote it as neatly
        as possible, and did not use anything requiring compilation such as Webpack, in the event it gets handed off to another coder.
        I told the client if he needed any small changes, I&apos;d happily do so.
      </p>
    </ModalBase>
  )
}