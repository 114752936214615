import './BackgroundPanner.scss';


export default function BackgroundPanner () {
    return (
        <div id="background-container" className='background'>
            <div className="layer background" id="background-layer-back"></div>
            <div className="layer background" id="background-layer-tri1"></div>
            <div className="layer background" id="background-layer-tri2"></div>
        </div>
    );
}
