import './App.scss';
import 'bootstrap/dist/js/bootstrap';

import Projects from './components/projects/Projects';
import About from './components/about/About';
import Title from './components/title/Title';
import Modals from './components/modals/Modals';
import BackgroundPanner from './components/backgroundPanner/BackgroundPanner';


import ModalDiscordBot       from './components/modals/ModalDiscordBot';
import ModalJWPaintAndRepair from "./components/modals/ModalJWPaintAndRepair";
import ModalHanoi            from "./components/modals/ModalHanoi";
import ModalTriviaApp        from "./components/modals/ModalTriviaApp";

const imageFolder = './assets/projects/';

import thumbDiscordBot from './assets/projects/discord-bot/thumb.png';
import thumbJWPAR from './assets/projects/jw-paint-and-repair/thumb.png';
import thumbHanoi from './assets/projects/hanoi/thumb.png';
import thumbTrivia from './assets/projects/trivia-app/thumb.png';

const projects = [
  {
      name: 'Discord Bot',
      description: 'Moderation tool for keeping chatrooms safe.',
      tags: ['Nodejs', 'Redis', 'APIs'],
      img: thumbDiscordBot,
      imgAlt: 'Discord logo and a bot logo.',
      modalId: 'modal-discord-bot',
      modal: <ModalDiscordBot/>
  },
  {
      name: 'JW Paint and Repair',
      description: 'Personal website for an independent contractor.',
      tags: ['Bootstrap', 'Design'],
      img: thumbJWPAR,
      imgAlt: 'A painter holding tools.',
      modalId: 'modal-jw-paint-and-repair',
      modal: <ModalJWPaintAndRepair/>
  },
  {
      name: 'Hanoi',
      description: 'Clone of the puzzle game Towers of Hanoi.',
      tags: ['VanillaJS', 'Canvas'],
      img: thumbHanoi,
      imgAlt: 'A pyramid shaped tower built of colored squares.',
      modalId: 'modal-hanoi',
      modal: <ModalHanoi/>
  },
  // {
  //     name: 'PTAC Tracker',
  //     description: 'Helps keep records on work done to PTACs.',
  //     tags: ['interdum', 'vehicula', 'sapien'],
  //     img: getImageUrl(imageFolder+'ptac-tracker/thumb.png'),
  //     imgAlt: 'Close up of PTAC buttons.',
  //     modalId: 'modal-ptac-tracker',
  //     // modal: 
  // },
  {
      name: 'Trivia App',
      description: 'Web app that asks multiple choice questions on common knowledge.',
      tags: ['React', 'API'],
      img: thumbTrivia,
      imgAlt: 'Speech bubble that says trivia app.',
      modalId: 'modal-trivia-app',
      modal: <ModalTriviaApp/>
  }
];


function App() {

  return <>
    <section id="hero-top" className="row justify-content-around">
      <Title className="col col-12 col-lg-2 order-lg-5"/>
      <Projects className="col col-12 col-lg-8" projects={projects}/>
    </section>
    {/* <Skills/> */}
    <About/>
    {/* <Contact/> */}
    <Modals projects={projects}/>
    <BackgroundPanner/>
    <div id="end-footer" style={{fontSize: '.45rem'}}>✌</div>
  </>;
}

export default App;


function relativePath(name) {
  return new URL(name, import.meta.url).href;
}