import './modalBase.scss';

/**
 * @example
 * //Creating a Modal and adding it to the project.
 * 
 * //ModalProjectName.jsx
 * import ModalBase from "./ModalBase";
 * export default function ModalProjectName() {
 *  return (
 *      <ModalBase id="modal-project-name" sourceUrl="https://link-to-project-source-code.com" liveUrl="https://link-to-live-project.com">
 *      </ModalBase>
 *  )
 * }
 * 
 * //App.jsx
 * import ModalProjectName from './modals/ModalProjectName.jsx';
 * 
 * const projects = [
 *  {
 *      name: 'Project Name',
 *      description: 'Project Description',
 *       tags: ['Tags', 'go', 'here', 'Backend', 'Frontend', 'Express'],
 *      img: getImageUrl(imageFolder+'project-name/thumb.png'),
 *      imgAlt: 'Logo of project here.',
 *      modalId: 'modal-project-name',
 *      modal: <ModalProjectName/>
 *  },
 * ];
 * 
 * @param {string} id - HTML ID attribute of modal needed so that it can be targetted and opened when the link is pressed.
 * @param {string} [sourceUrl] - Optional link of the source code shown at the bottom of the modal.
 * @param {string} [liveUrl]  - Optional link of the live running project shown at the bottom of the modal.
 */

// eslint-disable-next-line react/prop-types
const ModalBase = ({id, sourceUrl, liveUrl, children}) => {
    return (
        <div className="modal" tabIndex="-1" id={id}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <div className="d-grid gap-2 col-6 mx-auto">
                            {sourceUrl &&
                                <a className="btn btn-secondary" href={sourceUrl} rel='nofollow' target="_blank">Source code</a>
                            }
                            {liveUrl &&
                                <a className="btn btn-primary" href={liveUrl} rel='nofollow' target="_blank">View project</a>
                            }
                        </div>
                        <div>
                            <button type="button" className="btn-close d-block d-sm-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBase
