export default function Project(project) {
    return (
        <div className={"project " + project.className}>
            <h2 className="title">{project.name}</h2>
            <img className="project-thumbnail" src={project.img} alt={project.imgAlt ?? ''}></img>
            <p className="description">{project.description}</p>
            <ul className="tag-list">
                {project?.tags.map(tag =>
                    <li className="badge tag" key={project.name + tag}>{tag}</li>
                )}
            </ul>
            {project?.modal &&
                <a 
                    role="button" tabIndex="0" type="button" className="btn"
                    data-bs-toggle="modal" data-bs-target={'#' + project.modalId}
                    onClick={(e)=>e.target.blur()}
                >
                    <div className="button-background"></div>
                    <span className="button-text">View</span>
                </a>
            }
        </div>
    );
}