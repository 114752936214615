import GithubSVGEmbed    from '../../assets/socials/githubSVGEmbed'
import TreehouseSVGEmbed from '../../assets/socials/treehouseSVGEmbed'
import LeetCodeSVGEmbed  from '../../assets/socials/leetcodeSVGEmbed'
import './title.scss'

const socials = [
  {
    id: "social-github",
    text: 'Github',
    href: "https://github.com/jobbol",
    svg: <GithubSVGEmbed />
  },
  {
    id: "social-treehouse",
    text: 'Treehouse',
    href: "https://teamtreehouse.com/profiles/jobbol",
    svg: <TreehouseSVGEmbed />
  },
  {
    id: "social-leetcode",
    text: 'Leetcode',
    href: "https://leetcode.com/u/jobbol/",
    svg: <LeetCodeSVGEmbed />
  }
];

// eslint-disable-next-line react/prop-types
export default function Title({ className }) {
  return (
    <section id="title" className={className + 'container'}>
      <h1>
        <div className='name'>Josh Olson</div>
        <small className='description'>Full-stack Web Developer</small>
      </h1>

      <ul id='socials'>
        {socials.map(social => {
          return (
            <li key={social.id} className="item">
            {/*<li key={social.id} className="d-inline-block d-xl-block">*/}
              <a href={social.href} id={social.id} rel='nofollow' target='_blank'>
                {social.svg}
                <span className="label">{social.text}</span>
                {/*<span className="d-none d-xl-inline-block">{social.text}</span>*/}
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  )
}







