import { useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import css from 'react-syntax-highlighter/dist/esm/languages/hljs/css';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/htmlbars';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import './codeSnippet.scss';


//Add more languages if needed here.
SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('css', css);
SyntaxHighlighter.registerLanguage('html', html);

const fileExtensionToLanguage = {
    'jsx': 'javascript',
    'cjs': 'javascript',
    'js': 'javascript',
    'htm': 'html',
    'html': 'html',
    'css': 'css',
    'scss': 'css'
};


/**
 * Creates a code snippet component from a locally provided file.
 * Can either provide a url to the code using the src attribute,
 * or can provide raw code in string form using the code attribute.
 * @example
 * //Grabbing code from a file on disk.
 * <CodeSnippet src={relativePath("./example1.js")} language="javascript" height="200px"/>
 * 
 * function relativePath(name) {
 *  return new URL(name, import.meta.url).href;
 * }
 * 
 * @example
 * //Grabbing code from a file on disk.  Language is assumed based on file extension.
 * <CodeSnippet src={relativePath("./example1.js")}  />
 * 
 * @example
 * //Using a string literal inside the tags.
 * //Curly braces + backticks will make it multiline string.
 * //Inside the code, escape any curly braces or backticks if they appear.
 * //Use unspace to remove x amount of spaces caused by tabbing over the code block.
 * <CodeSnippet language="javascript" unspace="4">
 *   {`
        function hanoi (n, start, mid, end) {
            if (n === 0) {
                return;
            }
            hanoi(n-1, start, end, mid);
            console.log(\`Moved \${start} to \${end}\`);
            hanoi(n-1, mid, start, end);
        }

        hanoi(3, 'A', 'B', 'C');
 *   `}
 * </CodeSnippet>
 * 
 * @param {Object} params
 * @param {string} [params.src] - Location of the file containing code.  Use either src, code, or children.
 * @param {string} [params.code] - Raw code to parse.  Use either src, code, or children.
 * @param {children} [children] - Raw code to parse.  Use either src, code, or children.
 * 
 * @param {string} [params.language] - Language name to use.  Can be either javascript, css, or html.
 * If not given, language is assumed based on the file extension type.
 * @param {Number} [params.unspace] - Removes up to x spaces at beginning of lines.  Useful if you
 * want your code block to sit at the right indentation without transfering those tabs into the user view.
 * 
 * @param {string} [params.className] - className to apply to the component.
 * @param {string} [params.id] - id to apply to the component.
 * @param {Object} [params.style] - styling to apply to the component.
 * 
 * @param {string} [params.width] - Width of the element.
 * @param {string} [params.height] - Height of the element.
 *
 * @returns {jsx}
*/
export default function CodeSnippet({ src, code, children, unspace = 0, language, className = '', id, style={}, width, height }) {

    const [codeString, setCodeString] = useState('One moment.');
    code ||= children;

    if (!src && !code) {
        throw new Error('In <CodeSnippet>, src, code, or children must be set.');
    }
    if (src) {
        if (!language) {
            language = getLanguageFromFileExtension(src);
        }
        getCodeFromSrc({src, setCodeString});
    } else if (code && codeString === 'One moment.') {
        setCodeString(code.replaceAll(new RegExp(`(\\n) {1,${unspace}}`, 'g'), '$1'));
    }

    if (width) {
        style.width = width;
    }
    if (height) {
        style.height = height;
    }
    if (language) {
        language = language.toLowerCase();
    }

    return (
        <div className={"code-snippet " + className} id={id} style={style}>
        <SyntaxHighlighter language="javascript" style={docco}  wrapLongLines={true}>
            {codeString}
        </SyntaxHighlighter>
        </div>
    );
}




function getLanguageFromFileExtension (src) {
    const url = new URL(src);
    const fileType = url.pathname.match(/\.(.+$)/);

    if (!fileType) {
        throw new Error(
            'In <CodeSnippet>, a language attribute must be given, or it ' +
            'must be implied by the file extension. The file extension could ' +
            'not be found.'
        );
    }

    const language = fileExtensionToLanguage[fileType[1].toLowerCase()];

    if (!language) {
        throw new Error(
            'In <CodeSnippet>, a language attribute must be given, or it ' +
            'must be implied by the file extension. The file extension ' +
            `(${fileType[1]}) is not supported.`
        );
    }

    return language;
}


function appendRawSuffix (src) {
    //https://vite.dev/guide/assets.html#importing-asset-as-string

    if (src.match(/\?raw$/)) {
        return src;
    }
    return src += '?raw';
}

function getCodeFromSrc ({src, setCodeString}) {
    src = appendRawSuffix(src);
    
    (async () => {
        const response = await fetch(src);
        if (!response.ok) {
            throw new Error(`In <CodeSnippet>, could not get src ${src}`);
        }
        let text = (await response.text())
            .replaceAll('\\r\\n', '\n')
            .replace(/\/\/# sourceMappingURL=data:application.+$/, '')
            .replace(/";\n\n$/, '')
            .replace(/^export default "/, '');
        
        setCodeString(text);
    })();
}