import './HoverGif.scss';

/**
 * Creates a gif that remains static until hovered over.
 * Requires a separate static image to be displayed when not interacted with.
 * @param {Object} params
 * @param {string} params.srcStatic - src of the static image.
 * @param {string} params.srcGif - src of the animated image.
 * @param {string} [params.alt] - alt text on both the static and animated images.
 * @param {string} [params.className] - class attribute of the wrapping element.
 * @param {string} [params.id] - id attribute of the wrapping element.
 * @param {boolean} [params.showHint] - If false, disables the hover to preview text.
 */

// eslint-disable-next-line react/prop-types
export default function HoverGif({ srcStatic, srcGif, alt = '', className = '', id = '', style = {}, showHint=true}) {
    if (!srcStatic) {
        throw new Error('In HoverGif, srcStatic must be defined.');
    }
    if (!srcStatic) {
        throw new Error('In HoverGif, srcGif must be defined.');
    }
    return (
        <div className={'hover-gif-parent' + className} id={id} style={style}>
            {showHint && 
            <div className='hint-parent hint'>
                <div className='hint-text hint'>hover to preview</div>
            </div>}
            <img className="static" src={srcStatic} alt={alt} />
            <img className="gif" src={srcGif} alt={alt} />
        </div>
    );
}
