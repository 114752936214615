import ModalBase from "./ModalBase";
import CodeSnippet from "../codeSnippet/CodeSnippet";
import HoverGif from "../hoverGif/HoverGif";

import hanoiBot from '../../assets/projects/hanoi/hanoi-help.mp4';
import recursionGif from '../../assets/projects/hanoi/recursion.gif';
import recursionPng from '../../assets/projects/hanoi/recursion.png';
import './modalHanoi.scss';

export default function ModalHanoi() {
  return (
    <ModalBase id="modal-hanoi" sourceUrl="https://github.com/jobbol/hanoi" liveUrl="./projects/hanoi/index.html">
      <h1>Hanoi</h1>
      <p>A clone of the puzzle game Towers of Hanoi, written in vanilla Javascript and canvas.</p>
      <a className="btn btn-secondary mb-5 me-3" href="https://github.com/jobbol/hanoi" rel='nofollow' target="_blank">Source code</a>
      <a className="btn btn-primary mb-5" href="projects/hanoi/index.html" rel='nofollow' target="_blank">View project</a>

      <h2>Rules and learning approaches</h2>
      <p>
        The rules for the game are simple:
      </p>
      <ul>
        <li>Only one disk can be moved at a time.</li>
        <li>Only the disk at the top of a stack can be moved.</li>
        <li>A disk cannot be placed on top of another disk with smaller diameter.</li>
      </ul>
      <p>
        In written form this doesn&apos;t make nearly as much sense as it
        does in visual form &mdash; so I combined the two.
      </p>
      <video preload="auto" controls className="img-fluid d-block mx-auto" alt="Animation of the Towers of Hanoi bot showing how the puzzle is solved.">
        <source src={hanoiBot} type="video/mp4" ></source>
      </video>

      <p></p>
      <p>
        The user can now learn by two different learning approaches: watching and reading.
        It takes more effort to design these animations, but initial impressions matter.
        Users should be able to jump into the game as quick as possible, and not be held back by anything.
      </p>

      <h2>Game engine</h2>
      <p>
        The HTML canvas is very barebones and only provides a few drawing methods for lines, pixels, rectangles, etc.
        In order to build a game on top of this, I needed my own game engine.  I wrote a screen state handler, button components,
        resource load handler, and a keyboard input library.
      </p>

      <h2>Lessons learned</h2>
      <p>
        Writing a game engine from scratch was a great experience as learning tool, but it was very time consuming,
        it&apos;s not as reusable, and it had a few bugs in the end product:
      </p>
      <p>The keyboard input library would stop reading input if the user switched to a different window and came back.  It took a lot of debugging and I could not figure out what went wrong.</p>
      <p>The button spacing and sizes needed to be tweaked for each specific font size as I needed to use hard coded numbers.  This would slow down the development process in the long-term.</p>
      <p>Pre-ES5 Javascript did not have import and export.  Instead JS scripts needed to be loaded in an specific order on the DOM.</p>


      <h2>Bot player and algorithm explained</h2>
      <p>
        In addition to the learning by reading the rules, the user can learn by watching a bot play the whole game.
        The bot moves according to the following algorithm:
      </p>
      <ol>
        <code style={{ "whiteSpace": "pre-line" }}>
          1. Move n-1 blocks from A to B using C.<br/>  
          2. Move last block from A to C.<br />
          3. Move n-1 blocks from B to C using A.<br/>
        </code>
      </ol>
      <p>In English this means:</p>
      <ol>
        <li>Move a tower with one less block from start peg to middle peg using end peg.</li>
        <li>Move the bottom block from start to end.</li>
        <li>Move the tower in step 1 from middle peg to end peg.</li>
      </ol>

      <h3>Recursion and base case</h3>
      <HoverGif id="recursion-image" srcStatic={recursionPng} srcGif={recursionGif}
      alt="Animation showing how solving a smaller tower can be used to solve larger towers recursively."/>
      <p>
        In steps 1 and 3 we assume the smaller tower can be moved, even though it has not been proven yet.  Then within
        these steps, we solve for the smaller tower, and again within those steps. This becomes a recursive problem.
        Eventually we reach a tower one block tall that can not be reduced, and we no longer have step 1 and 3
        because there is nothing on top or underneath to move.  This tower we know for sure that it can be moved
        from the start to end. Now that we have something we can solve, we can go back up and solve the tower of
        size 2, then the tower of size 3, etc.
      </p>

      <h3 style={{clear: "right"}}>Relative pegs</h3>
      <p>
        The pegs are relative in position to the step we are solving.  This does not become clear until attempting
        to solve the puzzle recursively.  In step 1 you move the N-1 tower from A to B.  Then you look at the N-2 tower,
        but it no longer exists on peg A because it was moved to peg B.
      </p>
      <p>
        So really, step 1 should be to move the N-1 tower <em>out of the way</em>, so that in step 2, the last block can be moved
        onto the last peg, which progresses you towards the goal.
      </p>
      <p>
        The rest of the steps are relative in position as well.  Since you changed the first step and the following moves are
        based on those pegs.
      </p>
      <h3>Code</h3>
      <CodeSnippet language="javascript" unspace={12} height="300px">
        {`
          function hanoi (n, start, mid, end) {
            if (n === 0) {
              return;
            }
            hanoi(n-1, start, end, mid);
            console.log(\`Moved \${start} to \${end}\`);
            hanoi(n-1, mid, start, end);
          }

          hanoi(3, 'A', 'B', 'C');
        `}
      </CodeSnippet>

    </ModalBase>
  )
}