import './projects.scss';
import Project from "./Project";

// eslint-disable-next-line react/prop-types
export default function Projects({className, projects}) {
    return (
        <section id="projects" className={className}>
            <div className="row justify-content-center">
                {/* eslint-disable-next-line react/prop-types */}
                {projects.map(project => (
                    <div key={project.name} className="col col-12 col-sm-10 col-md-6 gx-md-5 gx-lg-0 gx-xl-4 gx-xxl-5">
                        <Project className="text-center text-md-start" {...project}></Project>
                    </div>
                ))}
            </div>
        </section>
    );
}